export class Header {
    constructor(headerClass, headerLogoClass, fixedClass) {
        this.headerClass = headerClass;
        this.header = document.querySelector(this.headerClass);
        this.init();
    }

    init() {
        if (this.header) {
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll() {
        if (window.scrollY > 0) {
            this.header.classList.add('fixed');
        } else {
            this.header.classList.remove('fixed');
        }
    }
}
