class ProgressBar {
    constructor () {
        this.listen();
    }

    listen() {
        document.addEventListener('scroll', this.progressBar);
    }

    progressBar() {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;

        document.getElementById("js-bar").style.width = `${scrolled}%`;
    }
}

export default ProgressBar;
